import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import i18next from "i18next";

import { tabsNames } from "../../utils/constants";
import LeaderboardParticipantsTable from "./LeaderboardParticipantsTable";
import LeaderboardTableRows from "./LeaderboardTableRows";
import LeaderboardUpdate from "./LeaderboardUpdate";
import { FC } from "react";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Badge, Button } from "@/components";
import { LeaderboardProps } from "../../types/props/leaderboardUiProps";

export const LeaderboardUI: FC<LeaderboardProps> = ({
  selectedFilterType,
  setPropsFilters,
  updatingTimer,
  updateLeaderboardHandler,
  currentUserInThisChallenge,
  isPreview,
  _loading,
  color,
  leaderboardData,
  participants,
  ref,
  refOfAll,
  inViewRefElementIndex,
  loading,
  game,
  isUserParticipated,
  MemoizedCurrUser,
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  return (
    <>
      <div className="challenge__leaderboard">
        <div className="leaderboard">
          <div className="leaderboard__heading">
            <h2>{i18next.t("challenge.leaderboard.headline")}</h2>
          </div>
          {!brand.event.isEvent && (
            <div className="leaderboard__tabs">
              <div className="badge-wrapper">
                {tabsNames.map((tabName) => (
                  <Badge
                    variant={
                      selectedFilterType === tabName ? "primary" : "secondary"
                    }
                    onClickAction={() => setPropsFilters(tabName)}
                    key={tabName}
                  >
                    {i18next
                      .t(`challenge.leaderboard.tabs.${tabName}`)
                      .toLowerCase()}
                  </Badge>
                ))}
              </div>
              <div className="leaderboard__refresh">
                {updatingTimer && (
                  <LeaderboardUpdate
                    isUpdated={updateLeaderboardHandler}
                    timerVal={updatingTimer}
                  />
                )}
              </div>
            </div>
          )}
          {(currentUserInThisChallenge?.userChallengeData &&
            Object.keys(currentUserInThisChallenge.userChallengeData).length >
              0) ||
          isUserParticipated ? (
            // double div to mimick the list with all user, but for current user score
            <div className="leaderboard__list leaderboard__list--user">
              <div className="leaderboard__item leaderboard__item--user">
                <div className="your-score">
                  <div className="your-score__heading">
                    {i18next.t("challenge.leaderboard.score.yours")}
                  </div>
                  <div className="your-score__content">
                    <LeaderboardTableRows
                      bgStyle=""
                      rank={
                        currentUserInThisChallenge?.userChallengeData?.rank ??
                        "_"
                      }
                      logo={currentUser.profileLogoUrl}
                      name={[currentUser.userName, currentUser.fullName]}
                      points={
                        currentUserInThisChallenge?.userChallengeData?.points ??
                        "_"
                      }
                      slug={currentUser.slug}
                      game={game}
                      pointsType={
                        currentUserInThisChallenge?.userChallengeData
                          ?.pointsType
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <MemoizedCurrUser />
          )}

          <div className="leaderboard__list">
            <LeaderboardParticipantsTable
              color={color}
              items={
                selectedFilterType === "ALL" ? leaderboardData : participants
              }
              observerRef={ref}
              observerRefAll={refOfAll}
              inViewIndex={inViewRefElementIndex}
              loading={_loading || loading}
              game={game}
            >
              <>
                {/* {_loading || loading ? <Spinner /> : ""} */}
                {!loading && selectedFilterType === "ALL" && (
                  <div className="alert">
                    {
                      <p className="alert__message">
                        {i18next.t(
                          "challenge.leaderboard.score.noParticipants"
                        )}
                      </p>
                    }

                    {currentUser.uid || !isPreview ? (
                      <div className="alert__action">
                        {/* <InviteButton slug={slug!} text="Invite" /> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {!_loading && selectedFilterType === "FRIENDS" && (
                  <div className="alert">
                    <p className="alert__message">
                      {i18next.t("challenge.leaderboard.score.noFriends")}
                    </p>
                    <div className="alert__action">
                      {/* <InviteButton slug={slug!} /> */}
                    </div>
                  </div>
                )}

                {!_loading && selectedFilterType === "FOLLOWING" && (
                  <div className="alert">
                    <p className="alert__message">
                      {i18next.t("challenge.leaderboard.score.noFollowing")}
                    </p>
                    <div className="alert__action">
                      <Button
                        variant="primary"
                        onClickAction={() => setPropsFilters("ALL")}
                      >
                        {i18next.t("challenge.buttons.tabs.back")}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            </LeaderboardParticipantsTable>
          </div>
        </div>
      </div>
    </>
  );
};
