import React, { useEffect } from "react";
import bgIcons from "../../src/features/auth/assets/icons_bg.png";
import { brand } from "../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { ToastContainer } from "react-toastify";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { auth } from "@/api/firebase/firebase.config";
import { useNavigate } from "react-router-dom";
import { useAuthPersistState } from "@/store/auth/auth.store";
import { Link } from "react-router-dom";
import { collectionDoc } from "@/api/collectionRef/collectionOperations";
import Consent from "@/components/Consent/Consent";
import i18next from "i18next";

export default function UnAuthorizedLayout({
  children,
  user,
}: {
  children: React.ReactNode;
  user?: boolean;
}) {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const userAuth = useAuthPersistState((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    if (!userAuth && currentUser) {
      return setProfile(null); // Remove profile from storage
    }

    if (currentUser) {
      return navigate("/dashboard");
    }

    async () => {
      const profileRef = await collectionDoc.profiles(userAuth.uid);
      const profile = profileRef.data();
      if (userAuth && !profile.userName) {
        return navigate("/enter-username");
      }
    };
  }, [currentUser, userAuth]);

  return (
    <>
      <main className="auth__layout">
        <Consent
          layout={brand.brandValue === "ELGIGANTEN" ? "small" : "default"}
        />
        <div className="authentication">
          <div className="authentication__background">
            <img src={bgIcons} alt="" />
          </div>

          <div className="authentication__content">
            <div
              className="authentication__logo"
              onClick={() => navigate("/landing")}
            >
              <img src={brand.logos.large} alt="auth brand image" />
            </div>
            {children}
            <div className="authentication__terms">
              <small>
                {i18next.t("auth.terms.1")}{" "}
                <Link to="/terms">{i18next.t("auth.terms.2")}</Link>{" "}
                {i18next.t("auth.terms.3")}{" "}
                <Link to="/privacy-policy">{i18next.t("auth.terms.4")}</Link>{" "}
                {i18next.t("auth.terms.5")}
              </small>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="dark"
          limit={3}
        />
      </main>
    </>
  );
}
