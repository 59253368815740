import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import LeaderboardTableRows from "./LeaderboardTableRows";
import { Participants } from "../../types/cloudFunctions/LeaderBoard";
import LeaderboardTableLoader from "@/components/skeletonLoaders/singleChallengeLoader/LeaderboardTableLoader";

const LeaderboardParticipantsTable: React.FunctionComponent<{
  items: Participants[] | undefined;
  color: string;
  observerRef: (node?: Element | null | undefined) => void;
  observerRefAll: (node?: Element | null | undefined) => void;
  children: React.ReactNode;
  inViewIndex: number;
  loading: boolean;
  game: string;
}> = ({
  items,
  children,
  observerRef,
  observerRefAll,
  inViewIndex,
  loading,
  game,
}) => {
  const findQueryMatch = window.matchMedia("(max-width:1550px)");
  const [isMatch, setIsMatch] = useState(findQueryMatch.matches);
  findQueryMatch.addEventListener("change", (event) => {
    if (event.matches) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  });

  return (
    <>
      {(items || []).map((item, index) => {
        return (
          <div
            ref={index === inViewIndex ? observerRef : null}
            className="leaderboard__item"
            key={item?.profile.uid}
          >
            <div ref={observerRefAll}>
              <LeaderboardTableRows
                bgStyle=""
                rank={index + 1}
                logo={item?.profile.profileLogoUrl}
                name={[item?.profile.userName, item?.profile.fullName]}
                points={
                  item?.userChallengeData.points !== null
                    ? item?.userChallengeData.points
                    : "-"
                }
                slug={item?.profile.slug}
                game={game}
                pointsType={item.userChallengeData.pointsType}
              />
            </div>
          </div>
        );
      })}

      {!items?.length && (
        <>
          {!loading && (
            <div
              className={`leaderboard__item leaderboard__item--info ${
                loading ? "wave" : ""
              }`}
            >
              {children}
            </div>
          )}

          {Array.from(Array(isMatch ? 2 : 4), () => {
            return (
              <div
                className={`leaderboard__item leaderboard__item--placeholder ${
                  loading ? "wave" : ""
                }`}
                key={v4()}
              />
            );
          })}
        </>
      )}

      {items && items.length < (isMatch ? 7 : 8) && items.length > 0 && (
        <>
          {Array.from(Array((isMatch ? 7 : 8) - items.length), () => {
            return (
              <div
                className={`leaderboard__item leaderboard__item--placeholder ${
                  loading ? "wave" : ""
                }`}
                key={v4()}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default LeaderboardParticipantsTable;
