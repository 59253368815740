import { memo, useEffect, useState, FC, useMemo } from "react";
import { SlugProps } from "@/features/challenge/types/props/SingleChallengeProps";
import LeaderboardTableRows from "./LeaderboardTableRows";
import LeaderboardUpdate from "./LeaderboardUpdate";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Badge, Button } from "@/components";
import LeaderboardParticipantsTable from "./LeaderboardParticipantsTable";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import {
  LeaderBoardBasedOnChallengeId_Props,
  LeaderBoardBasedOnChallengeId_Response,
} from "../../types/cloudFunctions/LeaderBoard";
import { tabsNames } from "../../utils/constants";

import { getAuthorProfileBasedOnUid } from "@/api/search/searchUsernameByName";
import { useInView } from "react-intersection-observer";
import { cacheDatabase } from "@/api/firebase/firebase.config";
import { brand } from "./../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import i18next from "i18next";
import { LeaderboardUI } from "./Leaderboard.ui";
import { useLeaderboardData } from "../../helperFunctions/useLeaderboardData";

export const Leaderboard: FC<
  SlugProps & {
    color: string;
    game: string;
    isPreview?: boolean;
    status?: string;
    client?: string;
  }
> = ({ slug, color, game, isPreview = false, status, client }): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [updatingTimer, setUpdatingTimer] = useState<number>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [selectedFilterType, setPropsFilters] = useState<
    "ALL" | "FRIENDS" | "FOLLOWING"
  >("ALL");

  const [refOfAll, inViewOfAll] = useInView();

  const updateLeaderboardHandler = (isTimeOut: boolean) => {
    if (isTimeOut) {
      setUpdatingTimer(undefined);
      setIsUpdated(true);
    }
  };

  const filterKey = selectedFilterType;

  const {
    leaderboard,
    hasMore,
    _loading,
    ref,
    fetchLeaderboard,
    leaderboardLiveData,
    currentUserDataInThisChallenge,
    loading,
    fetchLiveLeaderboard,
  } = useLeaderboardData({
    filterKey,
    slug,
    client,
    selectedFilterType,
    status,
    inViewOfAll,
  });

  // //ignore this code block; DO  NOT MODIFY IT ----------------

  const participants = leaderboard?.participants || [];
  console.log(leaderboard);

  // if user is participating then userChallengeData will not be undefined
  const isUserParticipated = !!currentUserDataInThisChallenge;

  useEffect(() => {
    const fetchData = async () => {
      if (
        (!leaderboard ||
          selectedFilterType === "FOLLOWING" ||
          selectedFilterType === "FRIENDS") &&
        slug
      ) {
        await fetchLeaderboard(
          {
            challengeId: slug!,
            currentUserId: currentUser.uid,
            type: selectedFilterType,
            lastVisibleItemNumber: 0,
          },
          filterKey,
          false
        );
      }

      if (selectedFilterType === "ALL") {
        if (!leaderboardLiveData) {
          await fetchLiveLeaderboard();
        }
      }
    };

    fetchData();
  }, [slug, selectedFilterType]);

  const inViewRefElementIndex = useMemo(() => {
    return leaderboard ? leaderboard?.participants.length - 1 : -1;
  }, [leaderboard, hasMore]);

  const currUser = () => (
    <div className="leaderboard__user">
      <div className="your-score">
        <div className="your-score__heading">
          {i18next.t("challenge.leaderboard.score.yours")}
        </div>
        <div className="your-score__content">
          <p> {i18next.t("challenge.leaderboard.score.description")}</p>
        </div>
      </div>
    </div>
  );

  const MemoizedCurrUser = memo(currUser);

  return (
    <LeaderboardUI
      selectedFilterType={selectedFilterType}
      setPropsFilters={setPropsFilters}
      updatingTimer={updatingTimer}
      updateLeaderboardHandler={updateLeaderboardHandler}
      currentUserInThisChallenge={currentUserDataInThisChallenge}
      isPreview={isPreview}
      _loading={_loading}
      color={color}
      leaderboardData={leaderboardLiveData}
      participants={participants}
      ref={ref}
      refOfAll={refOfAll}
      inViewRefElementIndex={inViewRefElementIndex}
      loading={loading}
      game={game}
      isUserParticipated={isUserParticipated}
      MemoizedCurrUser={MemoizedCurrUser}
    />
  );
};
