import React, { memo, FC } from "react";
import { Link } from "react-router-dom";
import { ChallengeTableRowProp } from "@/features/challenge/types/props/SingleChallengeProps";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { formattedTime } from "../../helperFunctions/formatMsToTime";

const LeaderboardTableRows: FC<ChallengeTableRowProp> = (
  props
): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const isCurrUser = props.slug === currentUser.slug;
  const linkToUser = isCurrUser ? "/profile" : `/profile/${props.slug}`;

  return (
    <div className="item">
      <div className="item__rank">{props.rank}</div>
      <div className="item__user">
        <div className="user">
          <div className="user__image">
            <div className="image">
              <div className="image__content">
                <BaseUserAvatar logoUrl={props.logo} userName={props.name[0]} />
              </div>
            </div>
          </div>

          <div className="user__name">
            {" "}
            <Link
              to={
                props.isPlaceholder ? `/challenges/${props.slug}` : linkToUser
              }
            >
              <span className="user__name--nickname">
                {props.name[0] || ""}
              </span>
              {/* <span className="user__name--fullname">
                {props.name[1] || ""}
              </span> */}
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`item__score ${
          props.isPlaceholder ? "placeholder placeholder-wave" : ""
        }`}
      >
        {" "}
        {props.game.includes("F1 20") || props.pointsType === "ms"
          ? formattedTime(props.points)
          : props.points}
      </div>
    </div>
  );
};
export default memo(LeaderboardTableRows);
