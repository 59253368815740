import { cacheDatabase } from "@/api/firebase/firebase.config";
import { getAuthorProfileBasedOnUid } from "@/api/search/searchUsernameByName";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useEffect, useState } from "react";
import {
  LeaderBoardBasedOnChallengeId_Props,
  LeaderBoardBasedOnChallengeId_Response,
} from "../types/cloudFunctions/LeaderBoard";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";

export const useLeaderboardData = ({
  filterKey,
  slug,
  client,
  selectedFilterType,
  status,
  inViewOfAll,
}) => {
  const databaseRef = cacheDatabase.ref("leaderboard");
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [leaderboardLiveData, setLeaderboardLiveData] = useState<any[]>([]);

  const [currentUserDataInThisChallenge, setCurrentUserDataInThisChallenge] =
    useState<any>({});

  console.log("DATA", currentUserDataInThisChallenge);

  const [
    leaderboard,
    lastFeedDateInSeconds,
    hasMore,
    _loading,
    error,
    fetchLeaderboard,
    ref,
    inView,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<
    LeaderBoardBasedOnChallengeId_Response,
    LeaderBoardBasedOnChallengeId_Props // props types for cloud function
  >(
    "getLeaderBoardForChallengeId",
    slug,
    filterKey,
    false // persisting
  );

  // Live leaderboard for all
  const fetchLiveLeaderboard = async () => {
    setLoading(true);
    databaseRef
      .child(slug)
      .orderByKey()
      .on("value", async (snapshot) => {
        if (snapshot.val()?.leaderboard) {
          const newLeaderboardDataArray: any[] = [];

          await Promise.all(
            snapshot
              .val()
              .leaderboard.filter((element) => {
                return element?.userId && Object.keys(element).length !== 0;
              })
              .map(async (val: any) => {
                let author;
                console.log(val);

                if (val?.userId === currentUser?.uid) {
                  console.log("user matches");

                  setCurrentUserDataInThisChallenge({
                    profile: currentUser,
                    userChallengeData: val.userChallengeData,
                  });

                  return newLeaderboardDataArray.push({
                    profile: currentUser,
                    userChallengeData: val.userChallengeData,
                  });
                }

                if (val && val.userId) {
                  const authorProfile = await getAuthorProfileBasedOnUid(
                    val.userId,
                    brand.brandValue
                  );

                  if (authorProfile) {
                    author = authorProfile;
                  } else {
                    if (client) {
                      const authorProfile2 = await getAuthorProfileBasedOnUid(
                        val.userId,
                        client
                      );

                      if (authorProfile2) {
                        author = authorProfile2;
                      }
                    }

                    if (!author) {
                      const authorProfile3 = await getAuthorProfileBasedOnUid(
                        val.userId,
                        "WOG"
                      );
                      if (authorProfile3) {
                        author = authorProfile3;
                      }
                    }
                  }

                  if (author) {
                    newLeaderboardDataArray.push({
                      profile: author,
                      userChallengeData: val.userChallengeData,
                    });
                  } else if (!author && val.userChallengeData) {
                    newLeaderboardDataArray.push({
                      profile: {
                        fullName: "",
                        slug: "",
                        uid: "",
                        userName: "Failed to load user's info",
                      },
                      userChallengeData: val.userChallengeData,
                    });
                  }
                }
              })
          );

          const sortedData = newLeaderboardDataArray.sort(
            (a, b) => a?.userChallengeData?.rank - b?.userChallengeData?.rank
          );

          setLeaderboardLiveData(sortedData);
        } else {
          fetchLeaderboard(
            {
              challengeId: slug!,
              currentUserId: currentUser.uid,
              type: selectedFilterType,
              lastVisibleItemNumber: 0,
            },
            filterKey,
            false
          );
        }
      });

    setLoading(false);
    if (status !== "ongoing") {
      databaseRef.off();
    }

    return;
  };

  //   Leaderboard for filters (friends, following)
  const fetchMoreLeaderboard = async () => {
    if (selectedFilterType === "ALL") {
      return fetchLiveLeaderboard();
    } else {
      await fetchLeaderboard(
        {
          challengeId: slug,
          currentUserId: currentUser.uid,
          type: selectedFilterType,
          lastVisibleItemNumber: lastFeedDateInSeconds,
        },
        selectedFilterType,
        true
      );
    }
  };

  useEffect(() => {
    if (status !== "ongoing") {
      databaseRef.off();
    }
  }, [status]);

  useEffect(() => {
    if (inView) {
      fetchMoreLeaderboard();
    }
  }, [inView]);

  useEffect(() => {
    if (selectedFilterType === "ALL") {
      fetchLiveLeaderboard();
    }
  }, [inViewOfAll]);

  useEffect(() => {
    console.log(leaderboard);

    if (leaderboard && leaderboard?.currentUser) {
      setCurrentUserDataInThisChallenge(leaderboard.currentUser);
    }
  }, [leaderboard]);

  useEffect(() => {
    if (leaderboardLiveData && leaderboardLiveData.length !== 0 && !loading) {
      const userDoc = leaderboardLiveData.find(
        (doc) => doc.userId === currentUser.uid
      );

      if (userDoc) {
        setCurrentUserDataInThisChallenge({
          profile: currentUser,
          userChallengeData: userDoc,
        });
      }
    }
  }, [leaderboardLiveData]);
  return {
    leaderboard,
    hasMore,
    _loading,
    ref,
    fetchLeaderboard,
    leaderboardLiveData,
    currentUserDataInThisChallenge,
    loading,
    fetchLiveLeaderboard,
  } as const;
};
