import { Button, DropDown, Spinner } from "@/components";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { EditProfileFormValues } from "@/features/profile/types/EditProfileTypes";
import { FC } from "react";
import { language, languages } from "@/utils/constants/languages";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { ReactComponent as ArrowRight } from "../../../../../../assets/actions/chevron-right.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { brand } from "../../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const EditProfileForm: FC<{
  editProfileValues: EditProfileFormValues;
  setFieldValue: any;
  errors: any;
  saveChanges: any;
  isSubmitting: boolean;
  hasNotChanged: boolean;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  editProfileValues,
  setFieldValue,
  errors,
  saveChanges,
  isSubmitting,
  hasNotChanged,
  setCurrentTab,
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();

  const isDiscordUser = currentUser?.authenticationMethod === "discord";
  const isTwitchUser = currentUser?.authenticationMethod === "twitch";

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="settings__group settings__group--username">
          <BaseTextInput
            label={i18next.t("strings.user_name")}
            textType="text"
            value={editProfileValues.userName.trim()}
            placeholder={i18next.t("strings.user_name_placeholder")}
            setObjectState={(e, val) => {
              if (val.length <= 22) {
                setFieldValue(e, val);
              }
            }}
            setObjectStateTargetName="userName"
            error={errors && errors["userName"]}
            isDisabled={isDiscordUser || isTwitchUser}
          />
        </div>
        <div className="settings__group settings__group--fullname">
          <BaseTextInput
            label={i18next.t("strings.full_name")}
            textType="text"
            value={editProfileValues.fullName}
            placeholder={i18next.t("strings.full_name_placeholder")}
            setObjectState={setFieldValue}
            setObjectStateTargetName="fullName"
            error={errors && errors["fullName"]}
          />
        </div>

        <div className="settings__group settings__group--description">
          <BaseTextInput
            label={i18next.t("strings.about")}
            textType="textarea"
            value={editProfileValues.profileDescription}
            placeholder={i18next.t("strings.about_placeholder")}
            setObjectState={setFieldValue}
            setObjectStateTargetName="profileDescription"
            error={errors && errors["profileDescription"]}
          />
        </div>
        {/* FOR EVENT, ADD FIELD FOR EMAIL */}
        {(brand.event.isEvent && brand.authMethod === "email") ||
        (brand.event.isEvent && brand.authMethod === "emailNoVerify") ? (
          <div className="settings__group settings__group--email">
            <BaseTextInput
              label="Email"
              textType="text"
              value={editProfileValues.email}
              placeholder="Your email address"
              setObjectState={setFieldValue}
              setObjectStateTargetName="email"
              error={errors && errors["email"]}
              isDisabled
            />
          </div>
        ) : (
          ""
        )}
        <div className="settings__group settings__group--terms">
          <BaseTextInput
            label={i18next.t("auth.popup.terms")}
            textType="checkmark"
            value={editProfileValues.termsAccepted}
            placeholder=""
            setObjectState={setFieldValue}
            setObjectStateTargetName="termsAccepted"
            error={errors && errors["termsAccepted"]}
          />
        </div>

        {brand.hasFeature.haslanguagePicker && (
          <div className="settings__group settings__group--language">
            <label className="settings__label">
              {i18next.t("settings.tabs.otherSettings")}
            </label>
            <select
              onChange={(e) => {
                setFieldValue("lang", e.target.value);
              }}
            >
              {language.map((lang) => {
                if (lang.value === editProfileValues.lang) {
                  return (
                    <option selected value={lang.value} key={lang.value}>
                      {lang.label}
                    </option>
                  );
                }
              })}
              {language.map((lang) => {
                if (lang.value !== editProfileValues.lang) {
                  return (
                    <option
                      value={lang.value}
                      key={lang.value}
                      onClick={() => {
                        setFieldValue("lang", lang.value);
                      }}
                    >
                      {lang.label}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        )}
        <div className="settings__group settings__group--buttons">
          <Button
            variant="secondary"
            onClickAction={() => navigate(siteRoutes["profile"])}
          >
            {i18next.t("buttons.cancel")}
          </Button>
          <Button
            variant="primary"
            onClickAction={saveChanges}
            disabled={isSubmitting || hasNotChanged}
          >
            {isSubmitting ? <Spinner /> : i18next.t("buttons.save_changes")}
          </Button>
        </div>
      </form>
      <div className="settings__group settings__group--pages">
        <h3>{i18next.t("settings.tabs.otherSettings")}</h3>
        <div>
          <Button
            variant="link"
            onClickAction={() => {
              setCurrentTab("Avatar");
            }}
          >
            <div>
              {currentUser.profileLogoUrl ? (
                <img src={currentUser.profileLogoUrl} alt="Current avatar" />
              ) : (
                <p> {currentUser.userName.slice(0, 1)} </p>
              )}
              Profile image{" "}
            </div>
            <ArrowRight />
          </Button>
          {brand.hasFeature.connections && (
            <Button
              variant="link"
              onClickAction={() => {
                setCurrentTab("Connections");
              }}
            >
              Connections <ArrowRight />
            </Button>
          )}
          {brand.hasFeature.themes && (
            <Button
              variant="link"
              onClickAction={() => {
                setCurrentTab("Themes");
              }}
            >
              Themes <ArrowRight />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
